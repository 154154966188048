/** @jsx jsx */
import { jsx } from 'theme-ui'
import { columnsHead, defaultSorted } from './columnsHead'

import React, { useEffect, useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'ramda'

import { PanelTable } from '../Table'
import 'react-table/react-table.css'
import { setAccessor, setOpenAccessor } from '../../actions/workspace'
import { fmtDateLong } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'
import { stringFormatted } from '../FormattedNumber'

const TradeList = ({ keycloak, tradeList, tradeUpdates, tabIndex, instruments, accounts }) => {
  const { t } = useContext(I18nContext)

  const getSubType = (operationAccount, symbol) => {
    const instrument = instruments.data[`${accounts[operationAccount]?.exchangeId}:${symbol}`]
    return instrument?.securitySubType ? instrument.securitySubType : instrument?.securityType || ''
  }

  const memoizedTableData = useMemo(
    () =>
      !tradeList || isEmpty(tradeList)
        ? []
        : tradeList.map(t => ({
          ...t,
          formatDate: fmtDateLong(t.date),
          productSubTypeFormat: getSubType(t.operationAccount, t.symbol),
          formatQuantity: +t.quantity,
          formatPrice: +t.price,
          formatAccount: accounts[t.operationAccount]?.name || '',
          formatBuyQuantity: stringFormatted(t.buyQuantity),
          buyCurrency: t.buyCurrency,
          formatSellQuantity: stringFormatted(t.sellQuantity),
          sellCurrency: t.sellCurrency,
        })),
    [tradeList, tradeUpdates, t]
  )

  return (
    <PanelTable
      rows={memoizedTableData}
      defaultSorted={defaultSorted}
      getColumns={() => columnsHead(t, tabIndex)}
      tabIndex={tabIndex}
    />
  )
}

const mapStateToProps = ({
  order: { tradeList, tradeUpdates },
  instruments,
  workspace: { workspaces, currentWorkspace },
  accounts: { data: accounts },
}) => ({
  tradeList,
  tradeUpdates,
  instruments,
  workspaces,
  currentWorkspace,
  accounts,
})
const mapDispatchToProps = { setAccessor, setOpenAccessor }

export default connect(mapStateToProps, mapDispatchToProps)(TradeList)
