/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext, useEffect, useState } from 'react'

import { mergeWith, over, lensPath } from 'ramda'
import { Content } from './style'
import { Dialog, DialogTitle, DialogBanner } from '../Dialog'
import { I18nContext } from '../../containers/i18n'
import IndeterminateLoader from './IndeterminateLoader'
import OrderData from './OrderData'
import OrderHistory from './OrderHistory'
import ModalActions from './ModalActions'
import { connect } from 'react-redux'
import { getOrder } from '../../actions/orders'
import { useThemeUI } from 'theme-ui'
import { generateOrderMessage } from '../../common/utilities/orderUtils'

const getFields = t => ({
  account: t('account'),
  clientId: t('clientId'),
  clientOrderId: t('clientOrderId'),
  decisorId: t('decisorId'),
  exchangeId: t('exchange'),
  execStatus: t('execStatus'),
  executorId: t('executorId'),
  expiry: t('expiry'),
  symbol: t('instrument'),
  limitPrice: t('orderedPrice'),
  orderType: t('type'),
  qty: t('orderQuantity'),
  side: t('side'),
  masterOrderId: t('masterOrderId'),
  // averagePrice: t('filledPrice'),
  currency: t('currency'),
  // date: t('dateLocal'),
  // orderId: t('orderId'),
  // orderStatus: t('status'),
  cumQty: t('cumQuantity'),
  pendingQty: t('pendingQuantity'),
  remainingQty: t('remainingQuantity'),
  tradeId: t('tradeId'),
  // text: t('text'),
  // orderRejectText: t('orderRejectText'),
  // tradingCapacity: t('tradingCapacity'),
  // updateDate: t('lastUpdateDate'),
  // orderCancelText: t('orderCancelText'),
  // stopPrice: t('stopPrice'),
  // expirationDate: t('expirationDate'),
})

const mergeData = (order, data, fields) =>
  mergeWith((name, data) => ({ name, data }), fields, order?.data || data)

const ModalOrderView = ({
  open,
  status,
  title,
  data,
  onClose,
  onCancel,
  onModify,
  token,
  //mapDispatchToProps
  order,
  accountsData,
  markets,
  //mapDispatchToProps
  getOrder,
}) => {
  const { t } = useContext(I18nContext)
  const [nameData, setNameData] = useState(mergeData(order, data, getFields(t)))
  const { colorMode } = useThemeUI()

  useEffect(() => {
    data?.masterOrderId && getOrder(data.masterOrderId)
  }, [data.masterOrderId])

  useEffect(() => {
    if (order?.data) {
      let json = mergeData(order, data, getFields(t))
      // Change id => name (fixId)
      json = over(
        lensPath(['account', 'data']),
        () => {
          const acc = accountsData[order.data.account]
          const accountText =
            (acc && (acc.fixAccount ? `${acc.name} (${acc.fixAccount})` : acc.name)) ||
            order.data.account
          return accountText
        },
        json
      )
      // Change exchangeId -> market name - market segment
      json = over(
        lensPath(['exchangeId', 'data']),
        () => {
          const market = markets[order.data.exchangeId]
          return market ? `${market.marketName} - ${market.segmentName}` : order.data.exchangeId
        },
        json
      )
      setNameData(json)
    }
  }, [order, order?.data])

  return (
    open &&
    nameData && (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle id="alert-dialog-title" disableTypography colormode={colorMode}>
          {title}
        </DialogTitle>
        <DialogBanner buy={nameData.side.data?.toLowerCase() === 'buy' ? true : false}>
          {generateOrderMessage(t, data, data.instrument)}
        </DialogBanner>
        <Content>
          <OrderData nameData={nameData}></OrderData>
          {order?.fetched ? (
            <OrderHistory data={nameData.updates}></OrderHistory>
          ) : (
            <IndeterminateLoader />
          )}
        </Content>
        <ModalActions
          status={status}
          order={order}
          onClose={onClose}
          onCancel={() => onCancel(data)}
          onModify={onModify}
        />
      </Dialog>
    )
  )
}

const mapStateToProps = ({
  order: { order },
  accounts: { data: accountsData },
  markets: { data: markets },
}) => ({
  order,
  accountsData,
  markets,
})

const mapDispatchToProps = {
  getOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalOrderView)
