export const widgetsEnum = {
  SPLIT_QUOTE: {
    type: 'SPLIT_QUOTE',
    literal: 'splitQuoteLabel',
    addLiteral: 'addSplitQuote',
    hasFilters: false,
    hasSettings: false,
    hasDownloads: false,
  },
  DEPTH_SPLIT_QUOTE: {
    type: 'DEPTH_SPLIT_QUOTE',
    literal: 'depthSplitQuoteLabel',
    addLiteral: 'addDepthSplitQuote',
    hasFilters: false,
    hasSettings: false,
    hasDownloads: false,
  },
  TABLE: {
    type: 'TABLE',
    literal: 'tableLabel',
    addLiteral: 'addTable',
    hasFilters: false,
    hasSettings: false,
    hasDownloads: false,
  },
  TRADE: {
    type: 'TRADE',
    literal: 'tradeLabel',
    addLiteral: 'addTrade',
    hasFilters: false,
    hasSettings: false,
    hasDownloads: false,
  },
  ORDER_LIST: {
    type: 'ORDER_LIST',
    literal: 'orderListLabel',
    addLiteral: 'addOrderList',
    hasFilters: true,
    hasSettings: true,
    hasDownloads: true,
  },
  TRADE_LIST: {
    type: 'TRADE_LIST',
    literal: 'tradeListLabel',
    addLiteral: 'addTradeList',
    hasFilters: true,
    hasSettings: true,
    hasDownloads: true,
  },
  PRICE_LIST: {
    type: 'PRICE_LIST',
    literal: 'priceListLabel',
    addLiteral: 'addPriceList',
    hasFilters: true,
    hasSettings: false,
    hasDownloads: false,
  },
  QUOTE_LIST: {
    type: 'QUOTE_LIST',
    literal: 'quoteListLabel',
    addLiteral: 'addQuoteList',
    hasFilters: true,
    hasSettings: false,
    hasDownloads: false,
  },
  POSITION_LIST: {
    type: 'POSITION_LIST',
    literal: 'positionListLabel',
    addLiteral: 'addPositionList',
    hasFilters: true,
    hasSettings: true,
    hasDownloads: true,
  },
  MARKET_DEPTH: {
    type: 'MARKET_DEPTH',
    literal: 'marketDepthLabel',
    addLiteral: 'addMarketDepth',
    hasFilters: false,
    hasSettings: false,
    hasDownloads: false,
  },
  ACCOUNT_POSITION_LIST: {
    type: 'ACCOUNT_POSITION_LIST',
    literal: 'accountPositionListLabel',
    addLiteral: 'addAccountPositionList',
    hasFilters: true,
    hasSettings: true,
    hasDownloads: true,
  },
  ACCOUNT_SUMMARY_LIST: {
    type: 'ACCOUNT_SUMMARY_LIST',
    literal: 'accountSummaryListLabel',
    addLiteral: 'addAccountSummaryList',
    hasFilters: true,
    hasSettings: true,
    hasDownloads: true,
  },
  ACCOUNT_GROUP: {
    type: 'ACCOUNT_GROUP',
    literal: 'accountGroupLabel',
    addLiteral: 'addAccountGroup',
    hasFilters: false,
    hasSettings: false,
    hasDownloads: true,
  },
}
Object.freeze(widgetsEnum)

export const widgetsTypes = Object.keys(widgetsEnum).reduce((acc, curr) => {
  acc[curr] = widgetsEnum[curr].type
  return acc
}, {})

export const widgetsTypesValues = Object.values(widgetsTypes)
