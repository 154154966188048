import { Client } from '@stomp/stompjs'

export const createClient = url => {

    const client = new Client({
        brokerURL: url,
        connectionTimeout: 5000,
        reconnectDelay: 15000,
        discardWebsocketOnCommFailure: true,
    });

    if (process.env.NODE_ENV !== 'development') {
        client.debug = () => { }
    }

    return client
}


export const activateWebsocket = (client, keycloak, dispatch, type, resuscribe) => {
    client.keycloak = keycloak
    client.dispatch = dispatch
    client.beforeConnect = () => {
        client.connectHeaders = { Authorization: `Bearer ${client.keycloak.token}` }
    }
    client.onConnect = () => {
        client.dispatch({ type: `${type}_SOCKET_CONNECTED` })
        resuscribe()
    }
    client.onDisconnect = () => {
        client.dispatch({ type: `${type}_SOCKET_DISCONNECTED` })
    }
    client.onWebSocketError = (error) => {
        console.error(`${type} Error connecting to WS`, error)
        client.dispatch({ type: `${type}_SOCKET_DISCONNECTED` })
    }
    client.onStompError = (error) => {
        console.error(`${type} Stomp Error`, error)
        client.deactivate()
    }
    client.onWebSocketClose = state => {
        console.warn(`${type} Websocket closed`, state)
        client.dispatch({ type: `${type}_SOCKET_DISCONNECTED` })
    }

    client.activate()
}