import {
  any,
  append,
  ifElse,
  indexBy,
  lensProp,
  lensPath,
  map,
  mergeLeft,
  over,
  prop,
  propEq,
  set,
  when,
} from 'ramda'

const defaultModal = {
  open: false,
  viewMode: false,
  title: '',
  message: '',
  order: true,
  data: {},
  action: {},
  status: '',
}

const defaultState = {
  connected: false,
  orderList: {},
  tradeList: [],
  modal: defaultModal,
  updates: 0,
  tradeUpdates: 0,
}

const defaultOrderData = { fetched: false, data: null }

const eqOrder = ({ masterOrderId }) => propEq('masterOrderId', masterOrderId)

const convertToMap = list => indexBy(prop('masterOrderId'), list)
// const convertToMap = list => list

const modify = payload =>
  ifElse(
    any(eqOrder(payload)),
    map(when(eqOrder(payload), mergeLeft(payload))),
    append({ ...payload, updateDate: payload.date })
  )

const lensOrderList2 = id => lensPath('orderList', id)

const modifyMap = payload =>
  ifElse(
    any(eqOrder(payload)),
    map(when(eqOrder(payload), mergeLeft(payload))),
    append({ ...payload, updateDate: payload.date })
  )

// set(lensOrderList2(payload?.masterOrderId), payload)

export default (state = defaultState, { type, payload } = {}) => {
  const lensModal = lensProp('modal')
  const lensOrderList = lensProp('orderList')

  const lensOrder = lensProp('order')
  const lensTrade = lensProp('tradeList')
  switch (type) {
    case 'ORDER_SOCKET_CONNECTED':
      return set(lensProp('connected'), true, state)
    case 'ORDER_SOCKET_DISCONNECTED':
      return set(lensProp('connected'), false, state)
    case 'ORDER_UPDATE':
      // console.warn('Processing update')
      // console.warn(payload)
      // console.warn(lensOrderList2(payload.masterOrderId))
      //return set(lensOrderList2(payload.masterOrderId), payload, state)
      //return over(lensOrderList, modifyMap(payload), state)
      state.orderList[payload.masterOrderId] = payload
      state.updates++
      // // console.warn('Processed')
      return state
    // return {
    //     ...state,
    //     orderList: {
    //         ...state.orderList,
    //         [payload.masterOrderId]: payload,
    //     },
    //     updates: state.updates + 1
    // }
    case 'TRADE_UPDATE':
      state.tradeList.push(payload)
      state.tradeUpdates++
      return state
    // return over(lensTrade, modify(payload), state)
    case 'ORDER_LIST_DATA':
      return set(lensOrderList, convertToMap(payload), state)
    case 'GET_ORDER_DATA_FULLFILLED':
      return set(lensOrder, { fetched: true, data: payload }, state)
    case 'GET_ORDER_DATA_PENDING':
    case 'GET_ORDER_DATA_CLEAN':
      return set(lensOrder, defaultOrderData, state)
    case 'TRADE_LIST_DATA':
      return set(lensTrade, payload, state)
    case 'SHOW_ORDER_DIALOG':
      return over(lensModal, mergeLeft({ ...payload, open: true }), state)
    case 'CLOSE_ORDER_DIALOG':
      return set(lensModal, defaultModal, state)
    default:
      return state
  }
}
