import { connect } from 'react-redux'
import React, { useContext } from 'react'

import CustomButton from '../CustomButton'
import { fmtDateyyyMMdd, orkValidate } from '../../common/utilities'

import { DialogActions } from '../Dialog'
import { unsubscribeSymbolStatic } from '../../actions/marketData'
import { I18nContext } from '../../containers/i18n'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import { showErrorDialog } from '../../actions/workspace'

const Actions = ({
  data,
  onSave,
  onClose,
  token,
  //connect
  unsubscribeSymbolStatic,
  showErrorDialog,
}) => {
  const { t } = useContext(I18nContext)

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  const { hasAnyRole } = useContext(PermissionsContext)

  const isSaveButtonDisabled =
    !data.quantity || data.quantity === '0' || !data.price || data.price === '0'

  const validateFields = () => {
    const isMemberTrader = hasAnyRole(['member_trader'])
    const orkValidation = orkValidate({
      clientId: data.clientId,
      decisorId: data.decisorId,
      executorId: data.executorId,
      tradingCapacity: data.tradingCapacity,
      securityType: data.instrument.securityType,
    })
    const isValid = !isMemberTrader || orkValidation.isValid
    !isValid && handleModal(t('orkError').toUpperCase())
    return isValid
  }

  const handleSave = () => {
    unsubscribeSymbolStatic({
      instrument: data.instrument,
      account: data.account,
      subscriptionType: 'tick',
    })
    onSave(
      {
        //...data,
        symbol: data.symbol,
        exchangeId: data.exchangeId,
        side: data.side,
        orderType: data.orderType,
        account: data.account,
        tif: data.tif,
        tradingCapacity: data.tradingCapacity,
        instrument: data.instrument,
        price: data.price,
        quantity: data.quantity,
        clientId: data.clientId,
        decisorId: data.decisorId,
        executorId: data.executorId,
        ...(data.tif === 'GTD' &&
          data.expirationDate && { expirationDate: fmtDateyyyMMdd(data.expirationDate) }),
        stopPrice: data.stopPrice,
        //accountId for group, id for account
        operationAccount: data.operationAccount.accountId || data.operationAccount.id,
        origClOrdId: data.clientOrderId,
      }
    )
    onClose()
  }

  return (
    <DialogActions>
      <CustomButton
        type={data.side?.toLowerCase()}
        size="large"
        width="27%"
        disabled={isSaveButtonDisabled}
        onClick={() => {
          validateFields() && handleSave()
        }}
      >
        {t('modifyOrder')}
      </CustomButton>
      <CustomButton
        type="cancel"
        size="large"
        width="27%"
        onClick={() => {
          unsubscribeSymbolStatic({
            instrument: data.instrument,
            account: data.account,
            subscriptionType: 'tick',
          })
          onClose()
        }}
      >
        {t('notModify')}
      </CustomButton>
    </DialogActions>
  )
}

const mapDispatchToProps = {
  unsubscribeSymbolStatic,
  showErrorDialog,
}

export default connect(null, mapDispatchToProps)(Actions)
