
import { stringFormatted } from '../../components/FormattedNumber'
import { fmtDateyyyMMdd, orkValidate } from '../../common/utilities'


export const generateOrderMessage = (t, info, instrument) => {
    const action = t(info.side == 'BUY' ? 'BUYACTION' : 'SELLACTION').toUpperCase()
    const inverseAction = t(info.side == 'BUY' ? 'SELLACTION' : 'BUYACTION').toUpperCase()
    const message = instrument.marketAllowsQuantityCurrency ?
        `${action} ${info.currency && info.currency === instrument.baseCurrency ? `${stringFormatted(info.quantity)} ` : ``}\
        ${instrument?.baseCurrency || ''}\
        ${" / "}\
        ${inverseAction} ${info.currency && info.currency === instrument.quoteCurrency ? `${stringFormatted(info.quantity)} ` : ``}\
        ${instrument?.quoteCurrency || ''}\
        ${" "}\
        ${info.orderType !== 'MARKET' ? ` @ ${info.price} ` : ` `}\
        ${t(info.orderType).toUpperCase()} ${t(info.tif).toUpperCase()}`
        : `${t(action).toUpperCase()} ${info.quantity} ${info.symbol}${info.orderType !== 'MARKET' ? ` @ ${info.price} ` : ` `}${t(info.orderType).toUpperCase()} ${t(info.tif).toUpperCase()}`
    return message
}

export const sendOrder = ({
    payload,
    currentAccount,
    instrument,
    hasAnyRole,
    t,
    isConfirmDialogRequired,
    makeOrderWithConfirm,
    makeOrder,
    keycloak,
    handleModal,
}) => {
    const selectedAccount = currentAccount[instrument.exchangeId]
    const needsOrk = instrument.marketHasOrk && hasAnyRole(['member_trader'])

    if (selectedAccount && selectedAccount.account) {
        const trading = {
            clientId: payload.clientId,
            decisorId: payload.decisorId,
            executorId: payload.executorId,
            tradingCapacity: payload.tradingCapacity,
            securityType: instrument.securityType,
        }
        const tif = payload.tif || instrument?.allowedTimeInForces[payload.orderType][0]
        const info = {
            symbol: instrument.symbol,
            exchangeId: instrument.exchangeId,
            side: payload.side,
            orderType: payload.orderType,
            //TODO: Corregir
            quantity: isNaN(payload.quantity) ? payload.quantity?.replaceAll(' ', '') : payload.quantity,
            tif,
            operationAccount: selectedAccount.account,
            ...(instrument?.marketAllowsQuantityCurrency && { currency: payload.currency }),
            ...(['LIMIT', 'STOP_LIMIT'].includes(payload.orderType) && { price: payload.price }),
            ...(['STOP_LIMIT'].includes(payload.orderType) && { stopPrice: payload.stopPrice || 0 }),
            ...(['GTD'].includes(tif) && payload.expirationDate && { expirationDate: fmtDateyyyMMdd(payload.expirationDate) }),
        }
        const infoData = needsOrk ? { ...info, ...trading } : { ...info }
        const message = generateOrderMessage(t, infoData, instrument)
        if (!needsOrk || orkValidate({ ...trading }).isValid) {
            isConfirmDialogRequired
                ? makeOrderWithConfirm(
                    infoData,
                    { body: message, title: t('confirmOrder'), selectedAccount }
                )
                : makeOrder(infoData)
        } else {
            handleModal(t('orkError').toUpperCase())
        }
    } else {
        handleModal(t('accountError'))
    }
}
