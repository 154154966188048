import config from '../common/config'
import { activateWebsocket, createClient } from '../common/ws'

let positionSubscription = null

const client = createClient(config().urls.positions)

export const connectToPositionsSocket = keycloak => dispatch => {
  activateWebsocket(client, keycloak, dispatch, 'POSITION', resuscribe)
}

const resuscribe = () => {
  client.subscribe('/private/position', data => {
    processMessages(data)
  }, (error) => {
    console.error("Error connecting to WS")
    console.error(error)
  })
  client.dispatch({ type: 'POSITIONS_SUBSCRIBED' })
  subscribeToNotifications(client.dispatch)
}

const processMessages = data => {
  const { type, positions, position } = JSON.parse(data.body)
  switch (type) {
    case 'position_list':
      let accounts = {}
      let positionsObject = {}
      positions.forEach(p => {
        const positionKey = `${p.instrumentId}:${p.account}`
        positionsObject = {
          ...positionsObject,
          [positionKey]: { ...p },
        }
        accounts = { ...accounts, [p.account]: {} }
      })
      client.dispatch({
        type: 'POSITION_LIST_DATA',
        payload: {
          positions: positionsObject,
          positionIds: Object.keys(positionsObject),
          accountsWithPositions: Object.keys(accounts),
        },
      })
      break
    case 'position_update':
      const key = `${position.instrumentId}:${position.account}`
      client.dispatch({
        type: 'POSITION_LIST_UPDATE',
        payload: { position: { ...position }, key: key },
      })
      break
    default:
      break
  }
}


export const unsubscribeFromPositions = () => dispatch => {
  positionSubscription && positionSubscription.unsubscribe()
  dispatch({ type: 'POSITIONS_UNSUBSCRIBED' })
}

const subscribeToNotifications = dispatch => {
  client.subscribe('/private/notification', data => {
    const body = JSON.parse(data.body)
    const { type, notification } = body
    if (type === 'notification_update') {
      dispatch({ type: 'NOTIFICATION_UPDATE', payload: notification })
    }
  })
}

export const setPositionsTabs = () => (dispatch, getState) => {
  const prevState = getState()
  let count = 0
  prevState.workspace.workspaces.forEach(
    ws =>
      ws.tabs &&
      ws.tabs.length > 0 &&
      ws.tabs.forEach(tab => {
        if (
          tab.type === 'POSITION_LIST' ||
          tab.type === 'ACCOUNT_POSITION_LIST' ||
          tab.type === 'ACCOUNT_SUMMARY_LIST'
        ) {
          count = count + 1
        }
      })
  )
  dispatch({ type: 'SET_POSITION_TABS', payload: count })
}

export const reducePositionTabs = () => (dispatch, getState) => {
  const count = getState().positions.count
  dispatch({ type: 'SET_POSITION_TABS', payload: count === 0 ? 0 : count - 1 })
}

export const closeNotificationDialog = () => ({
  type: 'CLOSE_NOTIFICATION_DIALOG',
})

export const setCalculatedPositions = payload => dispatch =>
  dispatch({ type: 'SET_CALCULATED_POSITIONS', payload })
