/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'
import React, { useState, useEffect, useContext } from 'react'
import {
  SignalWifi1Bar,
  SignalWifi2Bar,
  SignalWifi3Bar,
  SignalWifi4Bar,
  SignalWifiOff,
} from '@material-ui/icons'
import { clearInterval, setInterval } from 'timers'
import { Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { I18nContext } from '../../containers/i18n'


const getIconForCompatibleNavs = (wsDisconnected) => {
  const ping = navigator.connection.rtt
  if (!navigator.onLine) {
    return <SignalWifiOff sx={style.red} />
  } else {
    switch (true) {
      case ping === 0:
        return <SignalWifiOff sx={style.red} />
      case ping <= 200:
        return <SignalWifi4Bar sx={wsDisconnected ? style.yellow : style.green} />
      case ping <= 400:
        return <SignalWifi3Bar sx={wsDisconnected ? style.yellow : style.green} />
      case ping <= 600:
        return <SignalWifi2Bar sx={wsDisconnected ? style.yellow : style.orange} />
      default:
        return <SignalWifi1Bar sx={wsDisconnected ? style.yellow : style.orange} />
    }
  }
}

const getIconForOtherNavs = (wsDisconnected) => {
  return navigator.onLine ? <SignalWifi3Bar sx={wsDisconnected ? style.yellow : style.green} /> : <SignalWifiOff sx={style.red} />
}

const getIcon = (wsDisconnected) =>
  navigator.connection?.rtt ? getIconForCompatibleNavs(wsDisconnected) : getIconForOtherNavs(wsDisconnected)

const ConnectionIndicator = ({
  marketDataSocketConnected,
  orderSocketConnected,
  positionsSocketConnected, }) => {
  const { t } = useContext(I18nContext)
  const [, setRender] = useState({})
  useEffect(() => {
    const interval = setInterval(() => setRender({}), 5000)

    return () => clearInterval(interval)
  }, [])

  const wsDisconnected = !marketDataSocketConnected || !orderSocketConnected || !positionsSocketConnected
  const ping = navigator.connection?.rtt ? `${t('connectionPing')}: ${navigator.connection?.rtt}. ` : ""
  const tooltipText = `${t('connectionStatus')}: ${ping}${t('connectionWebsockets')}: ${t(!wsDisconnected)}`

  return (
    <div sx={style.connectionIconContainer}>
      <Tooltip title={tooltipText}>{getIcon(wsDisconnected)}</Tooltip>
    </div>
  )
}

const mapStateToProps = ({
  marketData: { connected: marketDataSocketConnected },
  order: { connected: orderSocketConnected },
  positions: { connected: positionsSocketConnected },
}) => ({
  marketDataSocketConnected,
  orderSocketConnected,
  positionsSocketConnected,
})


const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionIndicator)
