import { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { subscribeSymbolStatic, unsubscribeSymbolStatic } from '../actions/marketData'
import {
  setCalculatedPositions,
} from '../actions/positions'
import {
  findInstrument as findInstrumentData,
  fmtDateLong,
  getEurExchangeRate,
} from '../common/utilities'
import { I18nContext } from './i18n'

import { accountsSelector } from '../selectors/accounts'
import { hydratedInstrumentSelector, selectAccounts } from '../selectors/instruments'
import { tickSelector } from '../selectors/marketData'
import { positionSelector } from '../selectors/positions'
import { createSelector } from 'reselect'

function PositionCalculator({
  // State
  ticks,
  positions = undefined,
  positionIds = [],
  accountsWithPositions = [],
  activePanels = 0,
  positionSubscribed = false,
  instruments = undefined,
  accounts = undefined,
  currentAccount,
  // Dispach
  subscribeSymbolStatic,
  unsubscribeSymbolStatic,
  setCalculatedPositions,
}) {
  const [requestedSubscriptions, setRequestedSubscriptions] = useState([])
  const { t } = useContext(I18nContext)
  const [render, setRender] = useState({})
  useEffect(() => {
    const interval = setInterval(() => setRender({}), 500)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!activePanels && positionSubscribed) {
      requestedSubscriptions.forEach(id => {
        const position = positions[id]
        const instrument = instruments[position?.instrumentId]
        if (instrument) {
          unsubscribeSymbolStatic({
            instrument,
            subscriptionType: 'tick',
            account: position?.account,
          })
        }
      })

      setRequestedSubscriptions([])
    }
  }, [activePanels, positionSubscribed])

  useEffect(() => {
    if (!activePanels) {
      return
    }

    if (!positionIds || positionIds.length === 0) {
      return
    }

    const newIds = positionIds.filter(id => !requestedSubscriptions.includes(id))

    newIds.forEach(id => {
      const position = positions[id]
      const instrument = instruments[position?.instrumentId]
      if (instrument) {
        console.log('Subscribing to %s for position price updates', instrument.id)
        subscribeSymbolStatic({
          instrument,
          subscriptionType: 'tick',
          account: position?.account,
        })
        if (instrument.quoteCurrency != 'EUR') {
          const quoteEur = instruments[`${instrument.exchangeId}:${instrument.quoteCurrency}EUR`]
          if (quoteEur) {
            console.log(
              'Subscribing to %s to calculate EUR price for %s',
              quoteEur.id,
              instrument.id
            )
            subscribeSymbolStatic({
              instrument: quoteEur,
              subscriptionType: 'tick',
              account: position?.account,
            })
            return
          }

          if (instrument.baseCurrency == 'EUR') {
            // We will use 1/price directly
            console.log('Using the instrument itself to calculate EUR price for %s', instrument.id)
            return
          } else {
            const eurQuote = instruments[`${instrument.exchangeId}:EUR${instrument.quoteCurrency}`]
            if (eurQuote) {
              console.log(
                'Subscribing to %s to calculate EUR price for %s',
                eurQuote.id,
                instrument.id
              )
              subscribeSymbolStatic({
                instrument: eurQuote,
                subscriptionType: 'tick',
                account: position?.account,
              })
              return
            }
          }

          const eurUsd = instruments[`${instrument.exchangeId}:EURUSD`]

          if (instrument.quoteCurrency != 'USD') {
            const quoteUsd = instruments[`${instrument.exchangeId}:${instrument.quoteCurrency}USD`]
            if (eurUsd && quoteUsd) {
              console.log(
                'Subscribing to %s and %s to calculate EUR price for %s',
                eurUsd.id,
                quoteUsd.id,
                instrument.id
              )

              subscribeSymbolStatic({
                instrument: eurUsd,
                subscriptionType: 'tick',
                account: position?.account,
              })
              subscribeSymbolStatic({
                instrument: quoteUsd,
                subscriptionType: 'tick',
                account: position?.account,
              })
              return
            }
          }

          const usdQuote = instruments[`${instrument.exchangeId}:USD${instrument.quoteCurrency}`]
          if (eurUsd && usdQuote) {
            console.log(
              'Subscribing to %s and %s to calculate EUR price for %s',
              eurUsd.id,
              usdQuote.id,
              instrument.id
            )

            subscribeSymbolStatic({
              instrument: eurUsd,
              subscriptionType: 'tick',
              account: position?.account,
            })
            subscribeSymbolStatic({
              instrument: usdQuote,
              subscriptionType: 'tick',
              account: position?.account,
            })
            return
          }
        }
      }
    })

    setRequestedSubscriptions(positionIds)
  }, [positionIds, activePanels])

  function getNetPositionValue(netPosition, instrument) {
    return (instrument && +instrument?.contractMultiplier * +netPosition) || 0
  }

  function getUnrealized(position, netPositionValue, instrument, account) {
    if (ticks) {
      const data = findInstrumentData(instrument, ticks, account)
      if (data) {
        if (position.netPosition > 0) {
          return data.bidPrice ? (+data.bidPrice - +position.averagePrice) * +netPositionValue : 0
        } else {
          return data.askPrice ? (+data.askPrice - +position.averagePrice) * +netPositionValue : 0
        }
      }
    }
    return 0
  }

  function convertToEuro(quantity, quote, market, account) {
    if (!quote || !market || !account) {
      return quantity
    }
    if (quote === 'EUR') {
      return quantity
    }
    return quantity ? quantity * getEurExchangeRate(quote, ticks, market, instruments, account) : 0
  }

  function buildPositionData() {
    return positionIds.map(p => {
      const accountInfo = accounts[positions[p].account]
      const instrument = instruments[`${positions[p].instrumentId}`]
      const base = instrument?.baseCurrency
      const quote = instrument?.quoteCurrency
      const netPos = getNetPositionValue(positions[p].netPosition, instrument) || 0
      const unrealizedpl =
        getUnrealized(positions[p], netPos, instrument, positions[p].account) || 0
      const unrealizedeur =
        convertToEuro(
          unrealizedpl,
          quote,
          accounts[positions[p].account]?.exchangeId,
          positions[p].account
        ) || 0
      const realizedProfitEUR =
        convertToEuro(
          positions[p].realizedProfit,
          quote,
          accounts[positions[p].account]?.exchangeId,
          positions[p].account
        ) || 0
      const deferralVariationMarginEUR =
        convertToEuro(
          positions[p]?.deferralVariationMargin,
          quote,
          accounts[positions[p].account]?.exchangeId,
          positions[p]?.account
        ) || 0
      return {
        key: p,
        ...positions[p],
        account: accountInfo
          ? (accountInfo?.name && accountInfo?.fixAccount && `${accountInfo?.name} (${accountInfo?.fixAccount})`)
          || `${accountInfo?.name}`
          : positions[p].account,
        accountId: positions[p].account,
        format: fmtDateLong(positions[p].date),
        netPositionFormat: +positions[p].netPosition,
        netPositionValue: +netPos,
        averagePriceFormat: +positions[p].averagePrice.toFixed(instrument?.priceDecimals || 6),
        realizedProfit: positions[p].realizedProfit,
        realizedProfitEURValue:
          (realizedProfitEUR || realizedProfitEUR === 0) && +realizedProfitEUR.toFixed(2),
        unrealizedPnL: (unrealizedpl || unrealizedpl === 0) && +unrealizedpl,
        unrealizedPnLEURValue: (unrealizedeur || unrealizedeur === 0) && +unrealizedeur.toFixed(2),
        deferralVariationMargin: positions[p].deferralVariationMargin,
        deferralVariationMarginEURValue:
          (deferralVariationMarginEUR || deferralVariationMarginEUR === 0) &&
          +deferralVariationMarginEUR.toFixed(2),
        base: base,
        quote: quote,
      }
    })
  }

  function accountFilter(row) {
    return row.accountId === currentAccount?.[row.exchangeId]?.account
  }

  function buildAccountsSummaries(positionsData) {
    return accountsWithPositions.map(accountId => buildAccountSummary(accountId, positionsData))
  }

  function buildAccountSummary(accountId, positionsData) {
    let realizedProfitEURValue = 0
    let unrealizedPnLEURValue = 0
    let deferralVariationMarginEURValue = 0
    const accountInfo = accounts[accountId]
    let customerAccountMaxMargin
    let customerAccountTotalMargin
    let operationAccountTotalMargin
    let remainingCollateral

    positionsData.forEach(p => {
      if (p.accountId === accountId) {
        realizedProfitEURValue = realizedProfitEURValue + p.realizedProfitEURValue
        unrealizedPnLEURValue = unrealizedPnLEURValue + p.unrealizedPnLEURValue
        if (!customerAccountMaxMargin) {
          customerAccountMaxMargin = p.customerAccountMaxMargin
        }
        if (!customerAccountTotalMargin) {
          customerAccountTotalMargin = p.customerAccountTotalMargin
        }
        if (!operationAccountTotalMargin) {
          operationAccountTotalMargin = p.operationAccountTotalMargin
        }
        if (!remainingCollateral) {
          remainingCollateral =
            (parseFloat(customerAccountMaxMargin) - parseFloat(customerAccountTotalMargin)).toFixed(
              2
            ) || ''
        }
        deferralVariationMarginEURValue =
          deferralVariationMarginEURValue + p.deferralVariationMarginEURValue
      }
    })

    return {
      key: accountId,
      account: accountInfo
        ? accountInfo.fixAccount
          ? `${accountInfo.name} (${accountInfo.fixAccount})`
          : accountInfo.name
        : accountId,
      realizedProfitEURValue: realizedProfitEURValue?.toFixed(2) || 0,
      unrealizedPnLEURValue: unrealizedPnLEURValue?.toFixed(2) || 0,
      customerAccountMaxMargin: customerAccountMaxMargin && +customerAccountMaxMargin,
      customerAccountTotalMargin:
        customerAccountTotalMargin && (+customerAccountTotalMargin).toFixed(2),
      operationAccountTotalMargin:
        operationAccountTotalMargin && (+operationAccountTotalMargin).toFixed(2),
      remainingCollateral,
      deferralVariationMarginEURValue: deferralVariationMarginEURValue?.toFixed(2) || 0,
    }
  }

  useEffect(() => {
    if (positionIds?.length) {
      const positionsData = buildPositionData()
      setCalculatedPositions({
        calculatedPositions: positionsData,
        calculatedAccountPositions: (currentAccount && positionsData.filter(accountFilter)) || [],
        accountsSummaries: buildAccountsSummaries(positionsData),
      })
    }
  }, [render, t, currentAccount])

  return null
}
export const selectAccountIds = state => state.accounts.dataIds

const mapStateToProps = (state, ownProps) =>
  createSelector(
    hydratedInstrumentSelector,
    tickSelector,
    selectAccounts,
    selectAccountIds,
    accountsSelector,
    positionSelector,
    (instruments, ticks, accounts, accountIds, currentAccount, positions) => ({
      instruments,
      ticks,
      accounts,
      accountIds,
      currentAccount,
      positions: positions.data,
      positionIds: positions.dataIds,
      accountsWithPositions: positions.accountsWithPositions,
      activePanels: positions.count,
      positionSubscribed: positions.positionSubscribed,
    })
  )(state, ownProps)

const mapDispatchToProps = {
  subscribeSymbolStatic,
  unsubscribeSymbolStatic,
  setCalculatedPositions,
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionCalculator)
